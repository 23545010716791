<script setup lang="ts">
import { ListItemProps } from "~/components/Menu/Utils/ListItem.props"
import { useUserStore } from "~/stores/user"
import { storeToRefs } from "pinia"
import { PROFILE_SLUGS } from "~/utils/constants"

const { t } = useI18n()
const userStore = useUserStore()
const { user } = storeToRefs(userStore)

const authToken = useStatefulCookie("authToken")
const refreshToken = useStatefulCookie("refreshToken")
const commerceLayerToken = useStatefulCookie("cmlToken")

const listItems = ref<ListItemProps[]>([
  {
    iconType: "Board",
    slug: "",
    label: t("myAccount.navigationMenu.pinboard"),
    hiddenOnMobile: true
  },
  {
    iconType: "User",
    slug: PROFILE_SLUGS.INFO,
    label: t("myAccount.navigationMenu.myInfos")
  },
  {
    iconType: "Heart",
    slug: PROFILE_SLUGS.SAVED_FOR_LATER,
    label: t("myAccount.navigationMenu.saveForLater")
  },
  {
    iconType: "ShoppingBag",
    slug: PROFILE_SLUGS.ORDERS,
    label: t("myAccount.navigationMenu.myPurchasesAndRefounds")
  },
  {
    iconType: "Pin",
    slug: PROFILE_SLUGS.ADDRESS,
    label: t("myAccount.navigationMenu.myAddresses")
  },
  {
    iconType: "PriceTag",
    slug: PROFILE_SLUGS.COUPONS,
    label: t("myAccount.navigationMenu.myPromos")
  },
  {
    iconType: "Box",
    slug: PROFILE_SLUGS.AVAILABLE_PRODUCTS,
    label: t("myAccount.navigationMenu.productsAvailableAgain")
  }
])

const route = useRoute()

const routPath = computed(() => route.path)

onMounted(() => checkCurrentSlug(route?.path ?? ""))

watch(routPath, (newV) => {
  checkCurrentSlug(newV ?? "")
})

const deselectAllListItems = () => {
  listItems.value = listItems.value.map((i) => ({ ...i, isSelected: false }))
}

const selectListItems = (slugs: string[]) => {
  deselectAllListItems()

  listItems.value = listItems.value.map((i) => {
    if (slugs.some((s) => s === i.slug)) return { ...i, isSelected: true }
    return i
  })
}

const checkCurrentSlug = (path: string) => {
  let slugs = path.split("/").filter(Boolean)

  if (slugs.length === 1 && slugs[0] === PROFILE_SLUGS.ROOT)
    selectListItems([""])
  else selectListItems(slugs)
}

const logoutHandler = () => {
  authToken.value = null
  refreshToken.value = null
  commerceLayerToken.value = null
  useCart().deleteLoggedCartId()
  userStore.logout()
}
</script>

<template>
  <div class="wrapper-menu-my_account bg-white md:pb-28">
    <DisplayedUsername textBoldMobile />

    <div class="wrapper-list_items space-y-2 md:mb-4">
      <MenuUtilsListItem
        v-for="item in listItems"
        :key="item.label"
        v-bind="item"
        :class="[item.isSelected ? '' : 'hover:underline']"
      />
    </div>

    <div
      class="
        wrapper-cta-exit
        md:left-padded
        flex
        w-full
        justify-center
        border-t border-grey-100
        py-6
        md:justify-start
        md:py-8
      "
    >
      <button
        type="button"
        @click="logoutHandler"
        class="
          pig-medium-underlined
          flex
          items-center
          justify-center
          gap-2
          text-green-main
        "
      >
        {{ $t("myAccount.exit") }}
        <UtilsIcon name="LogOut.svg" color="green-main" class="h-6 w-6" />
      </button>
    </div>
  </div>
</template>
